import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import "../components/style.scss";
import "../components/columns.scss";
import Helmet from "react-helmet";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { FaWhatsapp } from "react-icons/fa";

export default () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: { eq: "images/autohemoterapia_beneficios.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            aspectRatio
            sizes
            base64
            src
            srcSet
          }
        }
      }
      imageTwo: file(relativePath: { eq: "images/beneficios_autohemoterapia.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            aspectRatio
            sizes
            base64
            src
            srcSet
          }
        }
      }
      imageThree: file(
        relativePath: { eq: "images/beneficios_de_autohemoterapia.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            aspectRatio
            sizes
            base64
            src
            srcSet
          }
        }
      }
    }
  `);
  return (
    <div>
      <Helmet>
        <title>Autohemoterapia: Beneficios en la salud</title>
        <meta
          name="description"
          content="Los beneficios de la autohemoterapia en tu salud son muchos. ¡A continuación te presentamos una lista de los más importantes!"
        ></meta>
        <meta name="robots" content="index,follow"></meta>
        <meta property="og:title" content="Beneficios de la autohemoterapia" />
        <meta
          property="og:description"
          content="Los beneficios de la autohemoterapia en tu salud son muchos. ¡A continuación te presentamos una lista de los más importantes!"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dpzoehc6i/image/upload/v1582235228/photo_2020-02-20_15-45-58_wb5bdm.jpg"
        />
        <meta
          property="og:url"
          content="https://autohemoterapia.mx/beneficios-en-la-salud"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <a
        href="https://api.whatsapp.com/send?phone=5215539994763&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20la%20vacuna%20de%20Autohemoterapia.%20"
        className="float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp size={45} className="my-float" />
      </a>
      <section className="hero is-medium atlas-2">
        <Navbar />
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column">
                <h1 className="subtitle is-2 is-size-5-mobile has-text-white has-text-weight-bold is-uppercase">
                  Autohemoterapia: beneficios en la salud
                </h1>
                <p className="subtitle is-5 is-size-6-mobile has-text-white">
                  La autohemoterapia es una gran alternativa y apoyo al combate
                  de algunas enfermedades crónicas y dolorosas. Mucha gente que
                  sufre dolores recurre a técnicas alternativas para buscar
                  aliados que le permitan mejorar su calidad de vida.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-foot"></div>
      </section>
      <section className="container" id="informacion">
        <div className="columns">
          <div className="column">
            <p className="subtitle is-5 is-size-6-mobile">
              Si llevas muchos años luchando contra la diabetes, alergias, acné,
              asma, artritis y lupus, la <strong>autohemoterapia</strong> puede
              ser el complemento ideal al tratamiento que tu médico te ha dado.{" "}
            </p>

            <p className="subtitle is-5 is-size-6-mobile">
              Pero,{" "}
              <strong>¿cuáles son los beneficios de la autohemoterapia?</strong>
            </p>

            <Img
              fluid={data.imageOne.childImageSharp.fluid}
              alt="Beneficios de la autohemoterapia"
            />

            <h2 className="subtitle is-2 is-size-5-mobile has-text-weight-bold is-uppercase has-text-centered">
              Beneficios de la autohemoterapia
            </h2>
            <p className="subtitle is-5 is-size-6-mobile">
              <ul>
                <li>
                  El principal beneficio de la autohemoterapia es que es natural
                  y, por lo tanto, no tiene efectos secundarios.{" "}
                </li>

                <li>
                  Tu propio cuerpo es usado para protegerte, por lo que no hay
                  posibilidad alguna de que esto te haga daño.{" "}
                </li>

                <li>
                  No tienes que comprar medicinas. Si sufres de alguna
                  enfermedad, sabes la angustia que representa comprar
                  medicinas. Y más cuando su precio se eleva.{" "}
                </li>

                <li>
                  Tu propia sangre no cuesta, así que olvídate de las
                  fluctuaciones de los precios del mercado.{" "}
                </li>

                <li>
                  La autohemoterapia ayuda a combatir enfermedades, pero también
                  a prevenirlas. El objetivo de la autohemoterapia es mejorar tu
                  sistema inmunológico.{" "}
                </li>

                <li>
                  Tener un buen sistema inmunológico es una gran ventaja para
                  combatir enfermedades, pero también es muy importante para
                  prevenir el desarrollo de nuevas enfermedades.{" "}
                </li>

                <li>
                  La autohemoterapia, al ser una técnica de impacto global en el
                  sistema inmune, tiene beneficios globales.{" "}
                </li>

                <li>
                  No es una terapia focalizada a una enfermedad, sino más bien
                  como un tratamiento auxiliar para varias enfermedades que se
                  combaten con un buen sistema inmunológico como diabetes,
                  alergias, acné, asma, artritis y lupus.
                </li>
              </ul>
            </p>
            <Img
              fluid={data.imageTwo.childImageSharp.fluid}
              alt="Autohemoterapia, beneficios y desventajas"
            />

            <h2 className="subtitle is-2 is-size-5-mobile has-text-weight-bold is-uppercase has-text-centered">
              Algunas desventajas de la autohemoterapia
            </h2>
            <p className="subtitle is-5 is-size-6-mobile">
              <ul>
                <li>
                  Al ser un procedimiento basado en el mejoramiento del sistema
                  inmunológico, la autohemoterapia es un proceso lento.{" "}
                </li>

                <li>
                  Esta falta de velocidad puede causar desesperación en la gente
                  que sufre enfermedades dolorosas.{" "}
                </li>

                <li>
                  La autohemoterapia es un proceso auxiliar. Por esto, debe ser
                  un tratamiento auxiliar y, por sí misma, no es la única forma
                  de combatir estas enfermedades. Por lo que, si estás enfermo,
                  tendrías que sumar un tratamiento más a los que ya realizas.{" "}
                </li>
              </ul>
            </p>
            <Img
              fluid={data.imageThree.childImageSharp.fluid}
              alt="Los beneficios de la autohemoterapia"
            />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
